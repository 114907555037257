import { useContext } from "react";
import ToastContext from "./toast";

/**
 * Add a toast notification to the UI.
 * @param {string} message, The message you want the toast to display
 */
export default function useToastContext() {
  return useContext(ToastContext);
}
