import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import { Authenticator } from "@aws-amplify/ui-react";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import DataDashboard from "./pages/dataDisplay";
import { useEffect } from "react";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { signOut } from "aws-amplify/auth";

import { fetchAuthSession } from "aws-amplify/auth";
import Dashboard from "./pages/dashboard";
import { ToastContextProvider } from "./config/toast";

LicenseInfo.setLicenseKey(
  "73342329d11ccf485666f74ff8e0af51Tz03ODAyNSxFPTE3MzA2NjY0ODMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: "4d2vv6vnrckj6v3k60ihd7r2qk",
      userPoolId: "us-east-1_zicxm1r3B",
    },
  },
  API: {
    REST: {
      "im-api": {
        endpoint: "https://t9lorhw1t6.execute-api.us-east-1.amazonaws.com/data",
        region: "us-east-1",
        headers: async () => {
          return { Authorization: authToken };
        },
      },
    },
  },
});

/*Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: "4d2vv6vnrckj6v3k60ihd7r2qk",
      userPoolId: "us-east-1_zicxm1r3B",
    },
  },
  API: {
    REST: {
      "im-api": {
        endpoint: "https://t9lorhw1t6.execute-api.us-east-1.amazonaws.com/data",
        region: "us-east-1",
        headers: async () => {
          return { Authorization: authToken };
        },
      },
    },
  },
});*/

const formFields = {
  signUp: {
    username: {
      label: "Email",
      placeholder: "Enter your Email",
      order: 1,
    },
    given_name: {
      label: "First Name",
      placeholder: "Enter your First Name",
      order: 2,
    },
    family_name: {
      label: "Last Name",
      placeholder: "Enter your Last Name",
      order: 3,
    },
    nickname: {
      label: "Team Number",
      placeholder: "Enter your Team Number",
      order: 3,
    },
    password: {
      order: 4,
    },
    confirm_password: {
      order: 5,
    },
  },
  signIn: {
    username: {
      label: "Email",
      placeholder: "Enter your Email",
      order: 1,
    },
  },
};

export default function App({ user }) {
  return (
    <BrowserRouter>
      <Authenticator formFields={formFields} variation="modal" si>
        <CssBaseline />
        <ToastContextProvider>
          <Dashboard user={user} signOut={signOut} />
        </ToastContextProvider>
      </Authenticator>
    </BrowserRouter>
  );
}
