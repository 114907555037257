import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Mapper from "../components/mapper";
import notebasic from "../images/noteBasic.png";

const AutoDriveDialog = ({
  open,
  handleCancel,
  handleSubmit,
  alliance = "",
}) => {
  const [robotPosition, setRobotPosition] = useState({});
  const [robotStatus, setRobotStatus] = useState(0);
  const [navArray, setNavArray] = useState([]);
  const [statusArray, setStatusArray] = useState([]);
  const [note1, setNote1] = useState({ status: 0 });
  const [note2, setNote2] = useState({ status: 0 });
  const [note3, setNote3] = useState({ status: 0 });
  const [note4, setNote4] = useState({ status: 0 });
  const [note5, setNote5] = useState({ status: 0 });
  const [note6, setNote6] = useState({ status: 0 });
  const [note7, setNote7] = useState({ status: 0 });
  const [note8, setNote8] = useState({ status: 0 });
  const width = window.innerWidth > 1000 ? 775 : window.innerWidth;
  const noteSize = width * 0.051;
  const xDiff = width * 0.024;
  const yDiff = width * 0.023;
  const [redSide, setRedSide] = useState(
    alliance.indexOf("R") !== -1 ? true : false
  );
  const [flipped, setFlipped] = useState(false);
  const [lineArray, setLineArray] = useState([]);
  const canvas = useRef();

  useEffect(() => {
    drawLines();
  }, [lineArray]);

  const handleClose = (update) => {
    if (update) {
      // add robot position
      let temp = {};
      temp.auto_values = statusArray;
      temp.auto_position = navArray;
      handleSubmit(temp);
    } else {
      handleCancel();
    }
  };

  const handleClick = (area) => {
    console.log(area);

    if (area?.title === "robotPosition") {
      // red flipped 1: -32, -25 : height 90 , width 55
      let rStatus =
        robotPosition.position === Number(area.name)
          ? robotStatus === 3
            ? 0
            : robotStatus + 1
          : 1;
      setRobotStatus(rStatus);
      setRobotPosition({
        position: Number(area.name),
        coords: area.scaledCoords,
        status: rStatus,
        nonscaled: area.coords,
      });

      let tempNav = [...navArray];
      let tempLineArray = [...lineArray];
      let tempStatusArray = [...statusArray];
      let number = -Number(area.name);
      if (tempNav[0] > 0) {
        tempStatusArray.unshift(number);
        setStatusArray(tempStatusArray);

        tempLineArray.unshift({ x: area.center[0], y: area.center[1] });
        setLineArray(tempLineArray);

        tempNav.unshift(number);
        setNavArray(tempNav);
      } else {
        let number = -Number(area.name);
        tempNav.splice(0, 1, number);
        setNavArray(tempNav);

        tempLineArray.splice(0, 1, { x: area.center[0], y: area.center[1] });
        setLineArray(tempLineArray);

        tempStatusArray.splice(0, 1, number);
        setStatusArray(tempStatusArray);
      }
    } else if (area?.title === "note") {
      let temp = {};
      let noteStartingIndex = 0;
      let index = -1;

      //temp.x = area?.center[0] - xDiff;
      //temp.y = area?.center[1] - yDiff;

      temp.x = area.scaledCoords[0];
      temp.y = area.scaledCoords[1];

      switch (area?.name) {
        case "note1":
          temp.status = note1.status === 3 ? 0 : note1.status + 1;
          noteStartingIndex = 1;
          setNote1(temp);
          break;
        case "note2":
          temp.status = note2.status === 3 ? 0 : note2.status + 1;
          noteStartingIndex = 4;
          setNote2(temp);
          break;
        case "note3":
          temp.status = note3.status === 3 ? 0 : note3.status + 1;
          noteStartingIndex = 7;
          setNote3(temp);
          break;
        case "note4":
          temp.status = note4.status === 3 ? 0 : note4.status + 1;
          noteStartingIndex = 10;
          setNote4(temp);
          break;
        case "note5":
          temp.status = note5.status === 3 ? 0 : note5.status + 1;
          noteStartingIndex = 13;
          setNote5(temp);
          break;
        case "note6":
          temp.status = note6.status === 3 ? 0 : note6.status + 1;
          noteStartingIndex = 16;
          setNote6(temp);
          break;
        case "note7":
          temp.status = note7.status === 3 ? 0 : note7.status + 1;
          noteStartingIndex = 19;
          setNote7(temp);
          break;
        case "note8":
          temp.status = note8.status === 3 ? 0 : note8.status + 1;
          noteStartingIndex = 22;
          setNote8(temp);
          break;
      }

      let tempNav = [...navArray];
      let tempStatusArray = [...statusArray];
      let tempLineArray = [...lineArray];
      // both arrays should be the same
      if (tempNav.length === 0) {
        setNavArray([area?.id]);

        setStatusArray([noteStartingIndex + temp.status]);
        setLineArray([{ x: area.center[0], y: area.center[1] }]);
      } else {
        index = tempNav.indexOf(area?.id);
        if (index === -1) {
          tempNav.push(area?.id);
          setNavArray(tempNav);

          tempLineArray.push({ x: area.center[0], y: area.center[1] });
          setLineArray(tempLineArray);

          tempStatusArray.push(noteStartingIndex + temp.status);
          setStatusArray(tempStatusArray);
        } else {
          // check if we need to remove it from the array
          if (temp.status === 0) {
            tempNav.splice(index, 1);
            setNavArray(tempNav);

            tempLineArray.splice(index, 1);
            setLineArray(tempLineArray);

            tempStatusArray.splice(index, 1);
            setStatusArray(tempStatusArray);
          } else {
            tempStatusArray.splice(index, 1, noteStartingIndex + temp.status);
            setStatusArray(tempStatusArray);
          }
        }
      }
    }
  };

  const status = (number) => {
    let image = notebasic;
    switch (number) {
      case 0:
        image = "#363636";
        break;
      case 1:
        image = "#00C444";
        break;
      case 2:
        image = "#C42929";
        break;
      case 3:
        image = "#1316C4";
        break;
      default:
        image = "#363636";
        break;
    }
    return image;
  };

  const robotColorStatus = (number) => {
    let image = "#363636";
    switch (number) {
      case 0:
        image = "#363636";
        break;
      case 1:
        image = "#f9ff13";
        break;
      case 2:
        image = "#00C444";
        break;
      case 3:
        image = "#C42929";
        break;
      default:
        image = "#363636";
        break;
    }
    return image;
  };

  const reset = () => {
    setNote1({ status: 0 });
    setNote2({ status: 0 });
    setNote3({ status: 0 });
    setNote4({ status: 0 });
    setNote5({ status: 0 });
    setNote6({ status: 0 });
    setNote7({ status: 0 });
    setNote8({ status: 0 });
    setLineArray([]);
    setNavArray([]);
    setStatusArray([]);
    setRobotPosition({});
  };

  const drawLines = (line) => {
    if (canvas.hasOwnProperty("current") && canvas.current !== undefined) {
      const ctx = canvas.current.children[1].getContext("2d");

      setLineArray((current) => {
        if (current.length > 1) {
          current.forEach((line, index) => {
            ctx.globalCompositeOperation = "destination-over";
            if (current.length !== index + 1) {
              ctx.beginPath();
              ctx.moveTo(line.x, line.y);
              ctx.lineTo(current[index + 1].x, current[index + 1].y);
              ctx.lineWidth = 3;
              ctx.strokeStyle = "#fff";

              // Draw the Path
              ctx.stroke();
            }
          });
        }

        return current;
      });

      setRobotPosition((current) => {
        if (current.hasOwnProperty("coords")) {
          ctx.globalCompositeOperation = "destination-under";
          if (current.coords.length > 0) {
            ctx.fillStyle = robotColorStatus(current.status);
            if (current.coords.length > 4) {
              ctx.beginPath();
              ctx.moveTo(current.coords[0], current.coords[1]);
              ctx.lineTo(current.coords[2], current.coords[3]);
              ctx.lineTo(current.coords[4], current.coords[5]);
              ctx.lineTo(current.coords[6], current.coords[7]);
              ctx.closePath();
              ctx.fill();
            } else {
              let rheight = current.coords[2] - current.coords[0];
              let rwidth = current.coords[3] - current.coords[1];
              ctx.fillRect(
                current.coords[0],
                current.coords[1],
                rheight,
                rwidth
              );
            }
          }
        }
        return current;
      });
    }
  };

  return (
    <Box sx={{ padding: "2px !important", margin: "0px !important" }}>
      <Dialog
        open={open}
        maxWidth={width * 0.9}
        sx={{ padding: "2px !important", margin: "0px !important" }}
      >
        <DialogContent
          sx={{
            maxWidth: `${width * 0.9}`,
            minWidth: `${width * 0.9}`,
            padding: "2px !important",
            margin: "0px !important",
            backgroundColor: "#363636",
          }}
        >
          <Mapper
            containerRef={canvas}
            onAreaClick={handleClick}
            parentWidth={width * 0.8}
            redAlliance={redSide}
            flipped={flipped}
            mapProps={() => console.log("props")}
            redrawlines={drawLines}
          />
          {note1.status !== 0 && (
            <div
              style={{
                backgroundColor: status(note1.status),
                width: noteSize,
                height: noteSize,
                position: "absolute",
                top: note1.y,
                left: note1.x,
              }}
            />
          )}
          {note2.status !== 0 && (
            <div
              style={{
                backgroundColor: status(note2.status),
                width: noteSize,
                height: noteSize,
                position: "absolute",
                top: note2.y,
                left: note2.x,
              }}
            />
          )}
          {note3.status !== 0 && (
            <div
              style={{
                backgroundColor: status(note3.status),
                width: noteSize,
                height: noteSize,
                position: "absolute",
                top: note3.y,
                left: note3.x,
              }}
            />
          )}
          {note4.status !== 0 && (
            <div
              style={{
                backgroundColor: status(note4.status),
                width: noteSize,
                height: noteSize,
                position: "absolute",
                top: note4.y,
                left: note4.x,
              }}
            />
          )}
          {note5.status !== 0 && (
            <div
              style={{
                backgroundColor: status(note5.status),
                width: noteSize,
                height: noteSize,
                position: "absolute",
                top: note5.y,
                left: note5.x,
              }}
            />
          )}
          {note6.status !== 0 && (
            <div
              style={{
                backgroundColor: status(note6.status),
                width: noteSize,
                height: noteSize,
                position: "absolute",
                top: note6.y,
                left: note6.x,
              }}
            />
          )}
          {note7.status !== 0 && (
            <div
              style={{
                backgroundColor: status(note7.status),
                width: noteSize,
                height: noteSize,
                position: "absolute",
                top: note7.y,
                left: note7.x,
              }}
            />
          )}
          {note8.status !== 0 && (
            <div
              style={{
                backgroundColor: status(note8.status),
                width: noteSize,
                height: noteSize,
                position: "absolute",
                top: note8.y,
                left: note8.x,
              }}
            />
          )}
          <Box>
            <Stack>
              <Button
                className="A"
                onClick={() => {
                  setRedSide(!redSide);
                  reset();
                }}
              >
                {redSide ? "Blue" : "Red"}
              </Button>
              <Button
                className="B"
                onClick={() => {
                  setFlipped(!flipped);
                  reset();
                }}
              >
                {flipped ? "Amp" : "Not Amp"}
              </Button>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleClose(true)} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AutoDriveDialog;
