import AccountCircle from "@mui/icons-material/AccountCircle";
import { ListItemButton } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect } from "react";
import { Link, Route, Routes } from "react-router-dom";
import CrescendoForm from "./crescendoForm";
import DataDashboard from "./dataDisplay";
import { isMobile } from "react-device-detect";

async function GetAllianceData() {
  const data = await fetch(`/api/blueAlliance`);
  let parsed = data.json();
  return parsed;
}

export default function Dashboard({ signOut, user }) {
  const [auth, setAuth] = React.useState(true);
  const [robotSide, setRobotSide] = React.useState(undefined);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuIndex, setMenuIndex] = React.useState(1);

  useEffect(() => {
    const fetchData = async () => {
      const result = await GetAllianceData();
      console.log(JSON.parse(result));
    };
    fetchData();
  }, []);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "#0a420a" }}>
        <Toolbar>
          {!isMobile && (
            <Typography
              variant="h6"
              component="div"
              sx={{ marginRight: "2rem" }}
            >
              Crescendo Data Collector
            </Typography>
          )}

          <Link to={"data"} style={{ textDecoration: "none", color: "#fff" }}>
            <ListItemButton
              onClick={() => setMenuIndex(1)}
              sx={{
                paddingTop: "3px",
                paddingBottom: "3px",
                backgroundColor: menuIndex === 1 ? "#5b9d5bc2" : "transparent",
                border: menuIndex === 1 ? "1px solid #5b9d5bc2" : "",
                borderRadius: "3",
              }}
            >
              <Typography component="div">Dashboard</Typography>
            </ListItemButton>
          </Link>
          <Link
            to={"crescendo"}
            style={{ textDecoration: "none", color: "#fff" }}
          >
            <ListItemButton
              onClick={() => setMenuIndex(2)}
              sx={{
                paddingTop: "3px",
                paddingBottom: "3px",
                flexGrow: "1",
                backgroundColor: menuIndex === 2 ? "#5b9d5bc2" : "transparent",
                border: menuIndex === 2 ? "1px solid #5b9d5bc2" : "",
                borderRadius: "3",
              }}
            >
              <Typography component="div">Crescendo</Typography>
            </ListItemButton>
          </Link>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: "1" }}
          ></Typography>
          {auth && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={signOut}>Sign Out</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          backgroundColor:
            robotSide === undefined
              ? "#cccccc"
              : robotSide === "red"
              ? "#ffb6b6"
              : "#b6b6ff",
          minHeight: "90vh",
          maxHeight: "max-content",
          position: "relative",
        }}
      >
        <Box>
          <Routes>
            <Route
              path="crescendo"
              element={
                <CrescendoForm
                  user={user}
                  signOut={signOut}
                  robotSide={(e) => setRobotSide(e)}
                  updateIndex={() => setMenuIndex(1)}
                />
              }
            />
            <Route
              path="data"
              element={<DataDashboard user={user} signOut={signOut} />}
            />
            <Route
              path="/"
              element={<DataDashboard user={user} signOut={signOut} />}
            />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
}
