import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";

export const matchCols = ({ handleEditClick, handleDeleteClick, id }) => {
  return [
    {
      headerName: "Robot Number",
      field: "robot_number",
      width: 150,
      editable: true,
    },
    {
      headerName: "Scout Notes",
      field: "scout_notes",
      width: 200,
      editable: true,
    },
    {
      headerName: "Strategist Notes",
      field: "strategist_notes",
      width: 200,
      editable: true,
    },
    {
      headerName: "Event",
      field: "event",
      width: 200,
      editable: true,
    },
    {
      headerName: "Scout Name",
      field: "scout_name",
      width: 200,
      editable: true,
    },
    {
      headerName: "Match Number",
      field: "match_number",
      width: 200,
      editable: true,
    },
    {
      headerName: "Left Auto",
      field: "left_auto",
      width: 200,
      editable: true,
    },
    {
      headerName: "Amp Auto",
      field: "amp_auto",
      width: 200,
      editable: true,
    },
    {
      headerName: "Amp Tele",
      field: "amp_tele",
      width: 200,
      editable: true,
    },
    {
      headerName: "Speaker Auto",
      field: "speaker_auto",
      width: 200,
      editable: true,
    },
    {
      headerName: "Speaker Tele",
      field: "speaker_tele",
      width: 200,
      editable: true,
    },
    {
      headerName: "Missed Amp Auto",
      field: "missed_amp_auto",
      width: 200,
      editable: true,
    },
    {
      headerName: "Missed Amp Tele",
      field: "missed_amp_tele",
      width: 200,
      editable: true,
    },
    {
      headerName: "Missed Speaker Auto",
      field: "missed_speaker_auto",
      width: 200,
      editable: true,
    },
    {
      headerName: "Missed Speaker Tele",
      field: "missed_speaker_tele",
      width: 200,
      editable: true,
    },
    {
      headerName: "Missed Trap",
      field: "missed_trap",
      width: 200,
      editable: true,
    },
    {
      headerName: "Placed Trap",
      field: "placed_trap",
      width: 200,
      editable: true,
    },
    {
      headerName: "Shot Trap",
      field: "shot_trap",
      width: 200,
      editable: true,
    },
    {
      headerName: "Floor Retrieval",
      field: "floor_retrieval",
      width: 200,
      editable: true,
    },
    {
      headerName: "Human Dropped In",
      field: "human_dropped_in",
      width: 200,
      editable: true,
    },
    {
      headerName: "End Position",
      field: "end_position",
      width: 200,
      editable: true,
    },
    {
      headerName: "Robot Harmony Count",
      field: "robot_harmony_count",
      width: 200,
      editable: true,
    },
    {
      headerName: "Speed",
      field: "speed",
      width: 200,
      editable: true,
    },
    {
      headerName: "Agility",
      field: "agility",
      width: 200,
      editable: true,
    },
    {
      headerName: "Strong Defense",
      field: "strong_defense",
      width: 200,
      editable: true,
    },
    {
      headerName: "Defense Targeted",
      field: "defense_targeted",
      width: 200,
      editable: true,
    },
    {
      headerName: "Shooting Close",
      field: "shooting_close",
      width: 200,
      editable: true,
    },
    {
      headerName: "Shooting Mid",
      field: "shooting_mid",
      width: 200,
      editable: true,
    },
    {
      headerName: "Shooting Far",
      field: "shooting_far",
      width: 200,
      editable: true,
    },
    {
      headerName: "Under Stage Often",
      field: "under_stage_often",
      width: 200,
      editable: true,
    },
    {
      headerName: "Under Stage Easily",
      field: "under_stage_easily",
      width: 200,
      editable: true,
    },
    {
      headerName: "Under Stage Possible",
      field: "under_stage_possible",
      width: 200,
      editable: true,
    },
    {
      headerName: "Concern Comms",
      field: "concern_comms",
      width: 200,
      editable: true,
    },
    {
      headerName: "Concern Note Stuck",
      field: "concern_note_stuck",
      width: 200,
      editable: true,
    },
    {
      headerName: "Concern Double Note",
      field: "concern_double_note",
      width: 200,
      editable: true,
    },
    {
      headerName: "Concern Robot Stuck",
      field: "concern_robot_stuck",
      width: 200,
      editable: true,
    },
    {
      headerName: "Concern Robot Stuck Stage",
      field: "concern_robot_stuck_stage",
      width: 200,
      editable: true,
    },
    {
      headerName: "Concern Almost Tipped",
      field: "concen_almost_tipped",
      width: 200,
      editable: true,
    },
    {
      headerName: "Concern Tipped",
      field: "concern_tipped",
      width: 200,
      editable: true,
    },
    {
      headerName: "Concern Damaged",
      field: "concern_damaged",
      width: 200,
      editable: true,
    },
    {
      headerName: "Concern Teammate Off Chain",
      field: "concern_teammate_off_chain",
      width: 200,
      editable: true,
    },
    {
      headerName: "Concern got in the way",
      field: "concern_got_in_way",
      width: 200,
      editable: true,
    },
    {
      headerName: "Concern Damaged Others",
      field: "concern_damaged_others",
      width: 200,
      editable: true,
    },
    {
      headerName: "Auto Positions",
      field: "auto_position",
      width: 200,
      editable: true,
    },
    {
      headerName: "Auto Values",
      field: "auto_values",
      width: 200,
      editable: true,
    },
  ];
};
