import { Alert } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { createContext, useState } from "react";
import { TOAST_SETTINGS } from "./constants";

const ToastContext = createContext();

export default ToastContext;

export function ToastContextProvider({ children }) {
  const [duration, setDuration] = useState(TOAST_SETTINGS.duration);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [anchorOrigin, setAnchorOrigin] = useState(
    TOAST_SETTINGS.anchorOrigin.topCenter
  );
  const [severity, setSeverity] = useState(TOAST_SETTINGS.severity.SUCCESS);

  const handleClose = () => {
    setOpen(false);
  };

  const addToast = (props) => {
    const { message, duration, anchorOrigin, severity } = props;
    if (duration) {
      setDuration(duration);
    }
    if (anchorOrigin) {
      setAnchorOrigin(anchorOrigin);
    }
    if (severity) {
      setSeverity(severity);
    }
    setMessage(message);
    setOpen(true);
  };

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={duration}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
      >
        <Alert variant="filled" onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
}
