import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Mapper from "../components/mapper";
import notebasic from "../images/noteBasic.png";

//const array = [2, 6, 10, 14];
const AutoDisplayDialog = ({
  open,
  handleCancel,
  handleSubmit,
  redAlliance = true,
  record,
}) => {
  const [robotPosition, setRobotPosition] = useState(0);
  const [robotStatus, setRobotStatus] = useState(1);
  const [note1, setNote1] = useState({ status: 0 });
  const [note2, setNote2] = useState({ status: 0 });
  const [note3, setNote3] = useState({ status: 0 });
  const [note4, setNote4] = useState({ status: 0 });
  const [note5, setNote5] = useState({ status: 0 });
  const [note6, setNote6] = useState({ status: 0 });
  const [note7, setNote7] = useState({ status: 0 });
  const [note8, setNote8] = useState({ status: 0 });
  const width = window.innerWidth > 1000 ? 775 : window.innerWidth;
  const noteSize = width * 0.051;
  const xDiff = width * 0.024;
  const yDiff = width * 0.023;
  const [redSide, setRedSide] = useState(redAlliance);
  const [index, setIndex] = useState(0);
  const [lineArray, setLineArray] = useState([]);
  const [map, setMap] = useState({});
  const [array, setAutoArray] = useState([]);
  const canvas = useRef();

  const REDMAP = {
    name: "my-map",
    // GET JSON FROM BELOW URL AS AN EXAMPLE
    areas: [
      {
        id: 1,
        title: "robotPosition",
        shape: "rect",
        name: "1",
        coords: [46, 193, 93, 270],
      },
      {
        id: 2,
        title: "robotPosition",
        shape: "poly",
        name: "2",
        coords: [16, 383, 83, 423, 70, 451, 1, 415],
      },
      {
        id: 3,
        title: "robotPosition",
        shape: "rect",
        name: "3",
        coords: [81, 468, 115, 553],
      },
      {
        id: 4,
        title: "robotPosition",
        shape: "poly",
        name: "4",
        coords: [68, 564, 88, 600, 19, 640, 1, 605],
      },
      {
        id: 5,
        title: "note",
        shape: "rect",
        name: "note1",
        coords: [215, 359, 270, 417],
        strokeColor: "",
        fillColor: "",
      },
      {
        id: 6,
        title: "note",
        shape: "rect",
        name: "note2",
        coords: [218, 485, 273, 538],
      },
      {
        id: 7,
        title: "note",
        shape: "rect",
        name: "note3",
        coords: [218, 610, 273, 660],
      },
      {
        id: 8,
        title: "note",
        shape: "rect",
        name: "note4",
        coords: [675, 76, 730, 133],
      },
      {
        id: 9,
        title: "note",
        shape: "rect",
        name: "note5",
        coords: [675, 219, 730, 276],
      },
      {
        id: 10,
        title: "note",
        shape: "rect",
        name: "note6",
        coords: [675, 362, 730, 416],
      },
      {
        id: 11,
        title: "note",
        shape: "rect",
        name: "note7",
        coords: [675, 505, 730, 556],
      },
      {
        id: 12,
        title: "note",
        shape: "rect",
        name: "note8",
        coords: [675, 648, 730, 696],
      },
    ],
  };

  const BLUEMAP = {
    name: "my-map",
    // GET JSON FROM BELOW URL AS AN EXAMPLE
    areas: [
      {
        id: 1,
        title: "robotPosition",
        shape: "rect",
        name: "1",
        coords: [680, 178, 730, 275],
      },
      {
        id: 2,
        title: "robotPosition",
        shape: "poly",
        name: "2",
        coords: [690, 422, 756, 384, 776, 417, 706, 455],
      },
      {
        id: 3,
        title: "robotPosition",
        shape: "rect",
        name: "3",
        coords: [660, 468, 697, 555],
      },
      {
        id: 4,
        title: "robotPosition",
        shape: "poly",
        name: "4",
        coords: [704, 564, 776, 608, 755, 640, 684, 598],
      },
      {
        id: 5,
        title: "note",
        shape: "rect",
        name: "note1",
        coords: [512, 365, 558, 417],
        strokeColor: "",
        fillColor: "",
      },
      {
        id: 6,
        title: "note",
        shape: "rect",
        name: "note2",
        coords: [509, 491, 554, 531],
      },
      {
        id: 7,
        title: "note",
        shape: "rect",
        name: "note3",
        coords: [509, 610, 554, 653],
      },
      {
        id: 8,
        title: "note",
        shape: "rect",
        name: "note4",
        coords: [53, 81, 93, 122],
      },
      {
        id: 9,
        title: "note",
        shape: "rect",
        name: "note5",
        coords: [53, 221, 93, 267],
      },
      {
        id: 10,
        title: "note",
        shape: "rect",
        name: "note6",
        coords: [53, 366, 93, 410],
      },
      {
        id: 11,
        title: "note",
        shape: "rect",
        name: "note7",
        coords: [53, 508, 93, 553],
      },
      {
        id: 12,
        title: "note",
        shape: "rect",
        name: "note8",
        coords: [53, 650, 93, 696],
      },
    ],
  };

  useEffect(() => {
    let mapWidth = width * 0.8;
    let mapRatio = mapWidth / 775;
    //let array = record?.auto_values;
    //console.log(record);
    if (array.length > 0) {
      const interval = setInterval(() => {
        //let array = record.auto_values;
        console.log(array);
        if (index >= array?.length) {
          setIndex(0);
          setNote1({ status: 0 });
          setNote2({ status: 0 });
          setNote3({ status: 0 });
          setNote4({ status: 0 });
          setNote5({ status: 0 });
          setNote6({ status: 0 });
          setNote7({ status: 0 });
          setNote8({ status: 0 });
          setLineArray([]);
          setRobotPosition({});
        } else {
          let value = array[index];
          let temp = {};
          let tempLineArray = [...lineArray];
          if (value < 0) {
            let noteCord = REDMAP.areas.find(
              (cord) => cord.name === `${Math.abs(value)}`
            );
            temp.x = noteCord.coords[0] * mapRatio;
            temp.y = noteCord.coords[1] * mapRatio;
            let rStatus = 1;
            setRobotStatus(rStatus);
            let scaledCoords = noteCord.coords.map((cord) => cord * mapRatio);
            setRobotPosition({
              position: 1,
              coords: scaledCoords,
              status: rStatus,
            });

            setLineArray([
              { x: temp.x + noteSize / 2, y: temp.y + noteSize / 2 },
            ]);
          } else if (value <= 3) {
            let noteCord = REDMAP.areas.find((cord) => cord.name === "note1");
            temp.x = noteCord.coords[0] * mapRatio;
            temp.y = noteCord.coords[1] * mapRatio;
            temp.status = value - 1;
            tempLineArray.push({
              x: temp.x + noteSize / 2,
              y: temp.y + noteSize / 2,
            });
            setLineArray(tempLineArray);
            setNote1(temp);
          } else if (value <= 6) {
            let noteCord = REDMAP.areas.find((cord) => cord.name === "note2");
            temp.x = noteCord.coords[0] * mapRatio;
            temp.y = noteCord.coords[1] * mapRatio;
            tempLineArray.push({
              x: temp.x + noteSize / 2,
              y: temp.y + noteSize / 2,
            });
            setLineArray(tempLineArray);
            temp.status = value - 4;
            setNote2(temp);
          } else if (value <= 9) {
            let noteCord = REDMAP.areas.find((cord) => cord.name === "note3");
            temp.x = noteCord.coords[0] * mapRatio;
            temp.y = noteCord.coords[1] * mapRatio;
            temp.status = value - 7;
            tempLineArray.push({
              x: temp.x + noteSize / 2,
              y: temp.y + noteSize / 2,
            });
            setLineArray(tempLineArray);
            setNote3(temp);
          } else if (value <= 12) {
            let noteCord = REDMAP.areas.find((cord) => cord.name === "note4");
            temp.x = noteCord.coords[0] * mapRatio;
            temp.y = noteCord.coords[1] * mapRatio;
            temp.status = value - 10;
            tempLineArray.push({
              x: temp.x + noteSize / 2,
              y: temp.y + noteSize / 2,
            });
            setLineArray(tempLineArray);
            setNote4(temp);
          } else if (value <= 15) {
            let noteCord = REDMAP.areas.find((cord) => cord.name === "note5");
            temp.x = noteCord.coords[0] * mapRatio;
            temp.y = noteCord.coords[1] * mapRatio;
            temp.status = value - 13;
            tempLineArray.push({
              x: temp.x + noteSize / 2,
              y: temp.y + noteSize / 2,
            });
            setLineArray(tempLineArray);
            setNote5(temp);
          } else if (value <= 18) {
            let noteCord = REDMAP.areas.find((cord) => cord.name === "note6");
            temp.x = noteCord.coords[0] * mapRatio;
            temp.y = noteCord.coords[1] * mapRatio;
            temp.status = value - 16;
            tempLineArray.push({
              x: temp.x + noteSize / 2,
              y: temp.y + noteSize / 2,
            });
            setLineArray(tempLineArray);
            setNote6(temp);
          } else if (value <= 21) {
            let noteCord = REDMAP.areas.find((cord) => cord.name === "note7");
            temp.x = noteCord.coords[0] * mapRatio;
            temp.y = noteCord.coords[1] * mapRatio;
            temp.status = value - 19;
            tempLineArray.push({
              x: temp.x + noteSize / 2,
              y: temp.y + noteSize / 2,
            });
            setLineArray(tempLineArray);
            setNote7(temp);
          } else if (value <= 24) {
            let noteCord = REDMAP.areas.find((cord) => cord.name === "note8");
            temp.x = noteCord.coords[0] * mapRatio;
            temp.y = noteCord.coords[1] * mapRatio;
            temp.status = value - 22;
            tempLineArray.push({
              x: temp.x + noteSize / 2,
              y: temp.y + noteSize / 2,
            });
            setLineArray(tempLineArray);
            setNote8(temp);
          }
          setIndex(index + 1);
        }
      }, 500);
      return () => clearInterval(interval);
    }
  }, [array, index]);

  useEffect(() => {
    if (canvas.hasOwnProperty("current") && canvas.current !== undefined) {
      const ctx = canvas.current.children[1].getContext("2d");
      ctx.globalCompositeOperation = "destination-over";
      if (lineArray.length > 1) {
        lineArray.forEach((line, index) => {
          if (lineArray.length !== index + 1) {
            ctx.beginPath();
            ctx.moveTo(line.x, line.y);
            ctx.lineTo(lineArray[index + 1].x, lineArray[index + 1].y);
            ctx.lineWidth = 3;
            ctx.strokeStyle = "#fff";
            // Draw the Path
            ctx.stroke();
          }
        });
      } else {
        ctx.clearRect(0, 0, width, width);
        ctx.beginPath();
      }
    }
  }, [lineArray]);

  useEffect(() => {
    if (canvas.hasOwnProperty("current") && canvas.current !== undefined) {
      const ctx = canvas.current.children[1].getContext("2d");
      ctx.globalCompositeOperation = "destination-under";
      if (robotPosition?.coords?.length > 0) {
        ctx.fillStyle = robotColorStatus(robotPosition.status);
        if (robotPosition.coords.length > 4) {
          ctx.beginPath();
          ctx.moveTo(robotPosition.coords[0], robotPosition.coords[1]);
          ctx.lineTo(robotPosition.coords[2], robotPosition.coords[3]);
          ctx.lineTo(robotPosition.coords[4], robotPosition.coords[5]);
          ctx.lineTo(robotPosition.coords[6], robotPosition.coords[7]);
          ctx.closePath();
          ctx.fill();
        } else {
          let rheight = robotPosition.coords[2] - robotPosition.coords[0];
          let rwidth = robotPosition.coords[3] - robotPosition.coords[1];
          ctx.fillRect(
            robotPosition.coords[0],
            robotPosition.coords[1],
            rheight,
            rwidth
          );
        }
      }
    }
  }, [robotPosition]);

  useEffect(() => {
    let parsed = record?.hasOwnProperty("auto_values")
      ? JSON.parse(record?.auto_values)
      : [];
    setAutoArray(parsed);
    setRobotPosition(Math.abs(record?.auto_values[0]));
    setMap(REDMAP);
  }, [open]);

  const handleClose = (update) => {
    handleCancel();
  };

  const status = (number) => {
    let image = notebasic;
    switch (number) {
      case 0:
        image = "#363636";
        break;
      case 1:
        image = "#00C444";
        break;
      case 2:
        image = "#C42929";
        break;
      case 3:
        image = "#1316C4";
        break;
      default:
        image = "#363636";
        break;
    }
    return image;
  };

  const robotColorStatus = (number) => {
    let image = "#363636";
    switch (number) {
      case 0:
        image = "#363636";
        break;
      case 1:
        image = "#f9ff13";
        break;
      case 2:
        image = "#00C444";
        break;
      case 3:
        image = "#C42929";
        break;
      default:
        image = "#363636";
        break;
    }
    return image;
  };

  const loadedProps = (a, b) => {};

  return (
    <Box sx={{ padding: "2px !important", margin: "0px !important" }}>
      <Dialog
        open={open}
        maxWidth={width * 0.9}
        sx={{ padding: "2px !important", margin: "0px !important" }}
      >
        <DialogContent
          sx={{
            maxWidth: `${width * 0.9}`,
            minWidth: `${width * 0.9}`,
            padding: "2px !important",
            margin: "0px !important",
            backgroundColor: "#363636",
          }}
        >
          <Mapper
            onAreaClick={() => console.log("here")}
            parentWidth={width * 0.8}
            redAlliance={redSide}
            flipped={false}
            containerRef={canvas}
            mapProps={(a, b) => loadedProps(a, b)}
            redrawlines={() => console.log("draw")}
            disabled={true}
          />
          {note1.status !== 0 && (
            <div
              style={{
                backgroundColor: status(note1.status),
                width: noteSize,
                height: noteSize,
                position: "absolute",
                top: note1.y,
                left: note1.x,
              }}
            />
          )}
          {note2.status !== 0 && (
            <div
              style={{
                backgroundColor: status(note2.status),
                width: noteSize,
                height: noteSize,
                position: "absolute",
                top: note2.y,
                left: note2.x,
              }}
            />
          )}
          {note3.status !== 0 && (
            <div
              style={{
                backgroundColor: status(note3.status),
                width: noteSize,
                height: noteSize,
                position: "absolute",
                top: note3.y,
                left: note3.x,
              }}
            />
          )}
          {note4.status !== 0 && (
            <div
              style={{
                backgroundColor: status(note4.status),
                width: noteSize,
                height: noteSize,
                position: "absolute",
                top: note4.y,
                left: note4.x,
              }}
            />
          )}
          {note5.status !== 0 && (
            <div
              style={{
                backgroundColor: status(note5.status),
                width: noteSize,
                height: noteSize,
                position: "absolute",
                top: note5.y,
                left: note5.x,
              }}
            />
          )}
          {note6.status !== 0 && (
            <div
              style={{
                backgroundColor: status(note6.status),
                width: noteSize,
                height: noteSize,
                position: "absolute",
                top: note6.y,
                left: note6.x,
              }}
            />
          )}
          {note7.status !== 0 && (
            <div
              style={{
                backgroundColor: status(note7.status),
                width: noteSize,
                height: noteSize,
                position: "absolute",
                top: note7.y,
                left: note7.x,
              }}
            />
          )}
          {note8.status !== 0 && (
            <div
              style={{
                backgroundColor: status(note8.status),
                width: noteSize,
                height: noteSize,
                position: "absolute",
                top: note8.y,
                left: note8.x,
              }}
            />
          )}

          {robotStatus.status !== 0 && (
            <div
              style={{
                backgroundColor: robotColorStatus(robotStatus),
                width: robotPosition.height,
                height: robotPosition.width,
                position: "absolute",
                top: robotPosition.x,
                left: robotPosition.y,
                zindex: 9999999,
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AutoDisplayDialog;
