import RouteIcon from "@mui/icons-material/Route";
import { Box, Grid, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DataGridPro, GridActionsCellItem, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExportContainer, GridToolbarFilterButton, GridToolbarQuickFilter, useGridApiContext, useGridApiRef } from "@mui/x-data-grid-pro";
import * as FileSaver from "file-saver";
import * as React from "react";
import { useEffect, useState } from "react";
import { getMatches } from "../api/matches";
import { matchCols } from "../columns/matchColumns";
import AutoDisplayDialog from "../dialogs/autoDialog";

async function GetMatches() {
  const data = await getMatches();
  return data;
}

const Div = styled("div", {
  name: "MuiDiv",
  overridesResolver: (props, styles) => {
    return [styles.root];
  },
})();

export default function DataDashboard() {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [showAuto, setShowAuto] = useState(false);
  const [row, setRow] = useState({});
  const apiRef = useGridApiRef();

  useEffect(() => {
    const fetchData = async () => {
      const result = await GetMatches();
      setData(result.body);
      setColumns(matchCols({ handleEditClick }));
    };
    fetchData();
  }, []);

  function showAttDialog(row) {
    setRow(row);
    setShowAuto(true);
  }

  useEffect(() => {
    setColumns([
      ...columns,
      {
        field: "actions",
        type: "actions",
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        headerName: "Actions",
        width: 200,
        getActions: (params) => {
          let id = params.id;
          let retArray = [];

          retArray.push(
            <GridActionsCellItem
              icon={<RouteIcon />}
              label="Display Auto"
              sx={{
                color: "#6d7073",
                "&:hover": { backgroundColor: "#b9b9b940", color: "#6d7073" },
              }}
              onClick={() => showAttDialog(params.row)}
            />
          );

          return retArray;
        },
      },
    ]);
  }, [columns, data]);

  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <JSONExportMenuItem />
    </GridToolbarExportContainer>
  );

  const handleEditClick = () => {};

  const exportMatches = () => {
    let str = JSON.stringify(data);

    const blob = new Blob([str], {
      type: "data:application/json;charset=utf-8",
    });
    FileSaver.saveAs(blob, "matches" + ".json");
  };

  const JSONExportMenuItem = (props) => {
    const { hideMenu } = props;
    const apiRef = useGridApiContext();

    return (
      <MenuItem
        onClick={async () => {
          exportMatches();
          hideMenu?.();
        }}
      >
        Export JSON
      </MenuItem>
    );
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <CustomExportButton />
      </GridToolbarContainer>
    );
  }

  return (
    <Box>
      {showAuto && <AutoDisplayDialog open={showAuto} handleCancel={() => setShowAuto(false)} record={row} />}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Div
            variant="outlined"
            sx={{
              borderRadius: 2,
              border: 1,
              borderColor: "#e0e0e0",
              backgroundColor: "#ffffff",
            }}
          >
            <Box sx={{ p: 2 }}>
              <DataGridPro
                rows={data}
                columns={columns}
                sx={{ display: "flex", minHeight: "125px" }}
                apiRef={apiRef}
                pagination
                pageSizeOptions={[5, 10, 25]}
                disableSelectionOnClick
                density="compact"
                editMode="row"
                disableVirtualization
                slots={{
                  toolbar: CustomToolbar,
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                  pinnedColumns: { right: ["actions"] },
                }}
              />
            </Box>
          </Div>
        </Grid>
      </Grid>
    </Box>
  );
}
