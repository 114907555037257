import { Amplify } from "aws-amplify";
import { get, put } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";

const awsconfig = {
  aws_project_region: "us-east-1",
};

Amplify.configure(awsconfig); // <=== Initialize Amplify with the exports config
const existingConfig = Amplify.getConfig(); // <=== the initialized config should now be returned to existingConfig

Amplify.configure({
  ...existingConfig, // <=== existingConfig instead of awsconfig
  API: {
    ...existingConfig.API,
    REST: {
      ...existingConfig.API?.REST,
      "im-api": {
        endpoint: "https://t9lorhw1t6.execute-api.us-east-1.amazonaws.com/data",
        region: "us-east-1",
      },
    },
  },
});

export async function getMatches(id = "") {
  const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
  try {
    const restOperation = get({
      apiName: "im-api",
      path: `/matches?id=${id}`,
      options: {
        headers: {
          Authorization: authToken,
        },
      },
    });
    const response = await restOperation.response;
    const json = await response.body.json();
    console.log(json);
    return json;
  } catch (e) {
    console.log(e);
  }
}

export async function createMatch(body) {
  const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
  try {
    const restOperation = put({
      apiName: "im-api",
      path: `/matches`,
      options: {
        headers: {
          Authorization: authToken,
        },
        body: {
          body,
        },
      },
    });
    const response = await restOperation.response;
    return response;
  } catch (e) {
    console.log(e);
  }
}

/*export function getMatchesFromBA(event = "VAGLE") {
  const url = `/server/blueAlliance`;
  let result = axios
    .get(url, {
      headers: {
        Authorization: `cmFjaGF1ODc6MGIzZWRjMTktM2ZlZS00ZDEwLTlmZjMtMjczMzA4YmVjMzdl`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response);
      return error;
    });
  return result;
}*/
