import React, { useEffect } from "react";
import ImageMapper from "react-img-mapper";
import blueField from "../images/blueStage.png";
import blueFieldFlipped from "../images/blueStageFlipped.png";
import redField from "../images/redStage.png";
import refieldFlipped from "../images/redStageFlipped.png";

const Mapper = ({
  onAreaClick,
  parentWidth,
  redAlliance,
  flipped,
  mapProps,
  updateCanvas,
  lineArray,
  containerRef,
  redrawlines,
  disabled = false,
}) => {
  const [selectedArea, setSelectedArea] = React.useState({});
  const [areaCounter, setAreaCounter] = React.useState(0);
  const [url, setURL] = React.useState("");
  const [map, setMap] = React.useState("");

  // there is 4 possible angles to view the field
  // amp side, non amp side, red or blue

  const BLUEMAP = {
    name: "my-map",
    // GET JSON FROM BELOW URL AS AN EXAMPLE
    areas: [
      {
        id: 1,
        title: "robotPosition",
        shape: "rect",
        name: "1",
        coords: [680, 178, 730, 275],
      },
      {
        id: 2,
        title: "robotPosition",
        shape: "poly",
        name: "2",
        coords: [690, 422, 756, 384, 776, 417, 706, 455],
      },
      {
        id: 3,
        title: "robotPosition",
        shape: "rect",
        name: "3",
        coords: [660, 468, 697, 555],
      },
      {
        id: 4,
        title: "robotPosition",
        shape: "poly",
        name: "4",
        coords: [704, 564, 776, 608, 755, 640, 684, 598],
      },
      {
        id: 5,
        title: "note",
        shape: "rect",
        name: "note1",
        coords: [512, 365, 558, 417],
        strokeColor: "",
        fillColor: "",
      },
      {
        id: 6,
        title: "note",
        shape: "rect",
        name: "note2",
        coords: [509, 491, 554, 531],
      },
      {
        id: 7,
        title: "note",
        shape: "rect",
        name: "note3",
        coords: [509, 610, 554, 653],
      },
      {
        id: 8,
        title: "note",
        shape: "rect",
        name: "note4",
        coords: [53, 81, 93, 122],
      },
      {
        id: 9,
        title: "note",
        shape: "rect",
        name: "note5",
        coords: [53, 221, 93, 267],
      },
      {
        id: 10,
        title: "note",
        shape: "rect",
        name: "note6",
        coords: [53, 366, 93, 410],
      },
      {
        id: 11,
        title: "note",
        shape: "rect",
        name: "note7",
        coords: [53, 508, 93, 553],
      },
      {
        id: 12,
        title: "note",
        shape: "rect",
        name: "note8",
        coords: [53, 650, 93, 696],
      },
    ],
  };

  const BLUEMAPFLIPPED = {
    name: "my-map",
    // GET JSON FROM BELOW URL AS AN EXAMPLE
    areas: [
      {
        id: 1,
        title: "robotPosition",
        shape: "rect",
        name: "1",
        coords: [680, 504, 730, 587],
      },
      {
        id: 2,
        title: "robotPosition",
        shape: "poly",
        name: "2",
        coords: [707, 321, 775, 360, 758, 391, 689, 351],
      },
      {
        id: 3,
        title: "robotPosition",
        shape: "rect",
        name: "3",
        coords: [660, 221, 697, 308],
      },
      {
        id: 4,
        title: "robotPosition",
        shape: "poly",
        name: "4",
        coords: [683, 177, 755, 133, 775, 169, 707, 210],
      },
      {
        id: 5,
        title: "note",
        shape: "rect",
        name: "note1",
        coords: [512, 365, 558, 417],
        strokeColor: "",
        fillColor: "",
      },
      {
        id: 6,
        title: "note",
        shape: "rect",
        name: "note2",
        coords: [509, 245, 554, 284],
      },
      {
        id: 7,
        title: "note",
        shape: "rect",
        name: "note3",
        coords: [509, 125, 554, 163],
      },
      {
        id: 8,
        title: "note",
        shape: "rect",
        name: "note8",
        coords: [53, 81, 93, 122],
      },
      {
        id: 9,
        title: "note",
        shape: "rect",
        name: "note7",
        coords: [53, 221, 93, 267],
      },
      {
        id: 10,
        title: "note",
        shape: "rect",
        name: "note6",
        coords: [53, 366, 93, 410],
      },
      {
        id: 11,
        title: "note",
        shape: "rect",
        name: "note5",
        coords: [53, 508, 93, 553],
      },
      {
        id: 12,
        title: "note",
        shape: "rect",
        name: "4",
        coords: [53, 650, 93, 696],
      },
    ],
  };

  const REDMAP = {
    name: "my-map",
    // GET JSON FROM BELOW URL AS AN EXAMPLE
    areas: [
      {
        id: 1,
        title: "robotPosition",
        shape: "rect",
        name: "1",
        coords: [46, 193, 93, 270],
      },
      {
        id: 2,
        title: "robotPosition",
        shape: "poly",
        name: "2",
        coords: [16, 383, 83, 423, 70, 451, 1, 415],
      },
      {
        id: 3,
        title: "robotPosition",
        shape: "rect",
        name: "3",
        coords: [81, 468, 115, 553],
      },
      {
        id: 4,
        title: "robotPosition",
        shape: "poly",
        name: "4",
        coords: [68, 564, 88, 600, 19, 640, 1, 605],
      },
      {
        id: 5,
        title: "note",
        shape: "rect",
        name: "note1",
        coords: [215, 359, 270, 417],
        strokeColor: "",
        fillColor: "",
      },
      {
        id: 6,
        title: "note",
        shape: "rect",
        name: "note2",
        coords: [218, 485, 273, 538],
      },
      {
        id: 7,
        title: "note",
        shape: "rect",
        name: "note3",
        coords: [218, 610, 273, 660],
      },
      {
        id: 8,
        title: "note",
        shape: "rect",
        name: "note4",
        coords: [675, 76, 730, 133],
      },
      {
        id: 9,
        title: "note",
        shape: "rect",
        name: "note5",
        coords: [675, 219, 730, 276],
      },
      {
        id: 10,
        title: "note",
        shape: "rect",
        name: "note6",
        coords: [675, 362, 730, 416],
      },
      {
        id: 11,
        title: "note",
        shape: "rect",
        name: "note7",
        coords: [675, 505, 730, 556],
      },
      {
        id: 12,
        title: "note",
        shape: "rect",
        name: "note8",
        coords: [675, 648, 730, 696],
      },
    ],
  };

  const REDMAPFLIPPED = {
    name: "my-map",
    // GET JSON FROM BELOW URL AS AN EXAMPLE
    areas: [
      {
        id: 1,
        title: "robotPosition",
        shape: "rect",
        name: "-4",
        strokeColor: "#e42a003d",
        fillColor: "#e42a003d",
        coords: [46, 501, 99, 588],
      },
      {
        id: 2,
        title: "robotPosition",
        shape: "poly",
        name: "-1",
        strokeColor: "#e42a003d",
        fillColor: "#e42a003d",
        coords: [0, 360, 65, 320, 85, 352, 19, 390],
      },
      {
        id: 3,
        title: "robotPosition",
        shape: "rect",
        name: "-2",
        strokeColor: "#e42a003d",
        fillColor: "#e42a003d",
        coords: [81, 221, 115, 306],
      },
      {
        id: 4,
        title: "robotPosition",
        shape: "poly",
        name: "-3",
        strokeColor: "#e42a003d",
        fillColor: "#e42a003d",
        coords: [20, 135, 90, 176, 74, 210, 1, 168],
      },
      {
        id: 5,
        title: "note",
        shape: "rect",
        name: "note1",
        strokeColor: "#e42a003d",
        fillColor: "#e42a003d",
        coords: [215, 359, 270, 417],
      },
      {
        id: 6,
        title: "note",
        shape: "rect",
        name: "note2",
        strokeColor: "#e42a003d",
        fillColor: "#e42a003d",
        coords: [218, 237, 273, 292],
      },
      {
        id: 7,
        title: "note",
        shape: "rect",
        name: "note3",
        strokeColor: "#e42a003d",
        fillColor: "#e42a003d",
        coords: [218, 116, 273, 165],
      },
      {
        id: 8,
        title: "note",
        shape: "rect",
        name: "note8",
        strokeColor: "#e42a003d",
        fillColor: "#e42a003d",
        coords: [675, 76, 730, 133],
      },
      {
        id: 9,
        title: "note",
        shape: "rect",
        name: "note7",
        strokeColor: "#e42a003d",
        fillColor: "#e42a003d",
        coords: [675, 219, 730, 276],
      },
      {
        id: 10,
        title: "note",
        shape: "rect",
        name: "note6",
        strokeColor: "#e42a003d",
        fillColor: "#e42a003d",
        coords: [675, 362, 730, 416],
      },
      {
        id: 11,
        title: "note",
        shape: "rect",
        name: "note5",
        strokeColor: "#e42a003d",
        fillColor: "#e42a003d",
        coords: [675, 505, 730, 556],
      },
      {
        id: 12,
        title: "note",
        shape: "rect",
        name: "note4",
        strokeColor: "#e42a003d",
        fillColor: "#e42a003d",
        coords: [675, 648, 730, 696],
      },
    ],
  };

  useEffect(() => {
    if (redAlliance) {
      if (flipped) {
        setURL(refieldFlipped);
        setMap(REDMAPFLIPPED);
      } else {
        setURL(redField);
        setMap(REDMAP);
      }
    } else {
      if (flipped) {
        setURL(blueFieldFlipped);
        setMap(BLUEMAPFLIPPED);
      } else {
        setURL(blueField);
        setMap(BLUEMAP);
      }
    }
  }, [redAlliance, flipped]);

  React.useEffect(() => {
    onAreaClick(selectedArea);
  }, [areaCounter]);

  const handleClick = (area) => {
    setAreaCounter((current) => {
      let value = current + 1;
      return value;
    });
    setSelectedArea(area);
  };

  const cancelDefault = (a, b, c) => {
    c?.preventDefault();
    c?.stopPropagation();
    c?.persist();

    /*if (canvas.hasOwnProperty("current")) {
      const ctx = canvas.current.children[1].getContext("2d");
      ctx.globalCompositeOperation = "destination-over";
      lineArray.forEach((line) => {
        ctx.beginPath();
        ctx.moveTo(line.x, line.y);
        ctx.lineTo(300, 150);
        ctx.lineWidth = 5;
        ctx.strokeStyle = "#fff";

        // Draw the Path
        ctx.stroke();
      });
    }*/
    redrawlines("redrawing");
  };

  return (
    <>
      <ImageMapper
        onMouseLeave={(a, b, c) => cancelDefault(a, b, c)}
        containerRef={containerRef}
        src={url}
        map={map}
        onClick={(area) => handleClick(area)}
        parentWidth={parentWidth}
        onTouchStart={(area) => handleClick(area)}
        onTouchEnd={(a, b, c) => cancelDefault(a, b, c)}
        responsive
        disabled={disabled}
      />
    </>
  );
};

export default Mapper;
