export const TOAST_SETTINGS = Object.freeze({
  duration: 5000,
  severity: {
    SUCCESS: "success",
    INFO: "info",
    WARNING: "warning",
    ERROR: "error",
  },
  anchorOrigin: {
    topCenter: {
      vertical: "top",
      horizontal: "center",
    },
    topRight: {
      vertical: "top",
      horizontal: "right",
    },
    bottomRight: {
      vertical: "bottom",
      horizontal: "right",
    },
    bottomCenter: {
      vertical: "bottom",
      horizontal: "center",
    },
    bottomLeft: {
      vertical: "bottom",
      horizontal: "left",
    },
    topLeft: {
      vertical: "top",
      horizontal: "left",
    },
  },
});
