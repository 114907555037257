import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { createMatch } from "../api/matches";
import { TOAST_SETTINGS } from "../config/constants";
import useToastContext from "../config/useToastContext";
import AutoDisplayDialog from "../dialogs/autoDialog";
import AutoDriveDialog from "../dialogs/autoDriveDialog";

const Div = styled("div", {
  name: "MuiDiv",
  overridesResolver: (props, styles) => {
    return [styles.root];
  },
})();

export default function CrescendoForm({ robotSide, updateIndex }) {
  console.log("rendering form");
  const [event, setEvent] = useState("VABLA");
  const [mobile, setMobile] = useState(isMobile);
  const [scoutNumber, setScoutNumber] = useState("Manual");
  const [robotPosition, setRobotPosition] = useState({});
  const [formData, updateFormData] = useState({
    amp_auto: 0,
    speaker_auto: 0,
    missed_amp_auto: 0,
    missed_speaker_auto: 0,
    amp_tele: 0,
    speaker_tele: 0,
    trap_tele: 0,
    missed_amp_tele: 0,
    missed_speaker_tele: 0,
    missed_trap: 0,
    robot_number: 0,
    scout_notes: "",
    scout_name: "",
    match_number: 0,
    left_auto: 0,
    placed_trap: 0,
    shot_trap: 0,
    floor_retrieval: 0,
    human_dropped_in: 0,
    end_position: 0,
    robot_harmony_count: 0,
    speed: 0,
    agility: 0,
    strong_defense: 0,
    defense_targeted: 0,
    shooting_close: 0,
    shooting_mid: 0,
    shooting_far: 0,
    under_stage_often: 0,
    under_stage_easily: 0,
    under_stage_possible: 0,
    concern_comms: 0,
    concern_note_stuck: 0,
    concern_double_note: 0,
    concern_robot_stuck: 0,
    concern_robot_stuck_stage: 0,
    concen_almost_tipped: 0,
    concern_tipped: 0,
    concern_damaged: 0,
    concern_teammate_off_chain: 0,
    concern_got_in_way: 0,
    concern_damaged_others: 0,
    concern_damaged_own: 0,
    auto_position: [],
    auto_values: [],
  });
  const [gameTimer, setGameTimer] = useState(0);
  const [visualTime, setVisualTime] = useState(0);
  const [tabs, setTabs] = useState("1");
  const addToast = useToastContext();
  const navigate = useNavigate();
  const [autoDialogOpen, setAutoDialogOpen] = useState(false);
  const [autoArray, setAutoArray] = useState([]);

  useEffect(() => {
    console.log(formData);
    console.log(isMobile);
  }, [formData]);

  useEffect(() => {
    setMobile(isMobile);
  }, []);

  const handleCreateMatch = async () => {
    let temp = {
      ...formData,
      event: event,
      strategist_notes: "",
      auto_values: autoArray.auto_values,
      auto_position: autoArray.auto_position,
    };
    const response = await createMatch(temp);
    if (response.statusCode === 200) {
      addToast({
        message: "Successfully Recorded the Match",
        severity: TOAST_SETTINGS.severity.SUCCESS,
      });
      updateIndex();
      navigate("/data");
    } else {
      addToast({
        message: "There was an error saving the match.",
        severity: TOAST_SETTINGS.severity.ERROR,
      });
    }
  };

  const handleScoutNumberChange = async (e) => {
    let value = e.target.value;
    setScoutNumber(value);
    if (
      e.target.value === "R1" ||
      e.target.value === "R2" ||
      e.target.value === "R3"
    ) {
      robotSide("red");
    } else if (
      e.target.value === "B1" ||
      e.target.value === "B2" ||
      e.target.value === "B3"
    ) {
      robotSide("blue");
    } else {
      robotSide(undefined);
    }
  };

  const handleEventChange = async (e) => {
    let value = e.target.value;
    setEvent(value);
    updateFormData({
      ...formData,
      [e.target.name]: `${e.target.value}`,
    });
  };

  const handleNumberChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: Number(e.target.value),
    });
  };

  const handleStringChange = (e) => {
    console.log(formData);
    updateFormData({
      ...formData,
      [e.target.name]: `${e.target.value}`,
    });
  };

  const handleRadioChange = async (e, propertyName) => {
    let value = e.target.value;
    updateFormData({
      ...formData,
      [e.target.name]: Number(e.target.value),
    });
  };

  const handleCheckBoxChange = (e) => {
    let value = e.target.checked;

    updateFormData({
      ...formData,
      [e.target.name]: Boolean(value) ? 1 : 0,
    });
  };

  const buttonChange = (math, propertyName) => {
    let value = formData[propertyName];
    if (value === undefined) {
      value = 0;
    }
    math === "add" ? (value = value + 1) : (value = value - 1);
    if (value <= 0) {
      value = 0;
    }
    updateFormData({
      ...formData,
      [propertyName]: Number(value),
    });
  };

  const handleTabChange = (e, newValue) => {
    console.log(e);
    setTabs(newValue);
  };
  const startMatch = async (e) => {
    setTabs("1");
    setAutoDialogOpen(true);
    let timerId = setInterval(countdown, 1000);
    let timer = 0;
    function countdown() {
      if (timer === 17) {
        setTabs("2");
        timer++;
      } else if (timer === 153) {
        clearTimeout(timerId);
      } else {
        timer++;
        setGameTimer(timer);
      }

      let newVisualTime;
      if (timer <= 15) {
        newVisualTime = timer;
      } else if (timer > 15 && timer < 18) {
        newVisualTime = 15;
      } else {
        newVisualTime = timer - 3;
      }

      setVisualTime(newVisualTime);
    }
  };

  const closeAutoDialog = async (e) => {
    setAutoArray(e);
    setAutoDialogOpen(false);
  };

  return (
    <>
      <AutoDriveDialog
        open={autoDialogOpen}
        handleCancel={() => setAutoDialogOpen(false)}
        handleSubmit={(e) => closeAutoDialog(e)}
        alliance={scoutNumber}
      />
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Div
              id="foreground"
              variant="outlined"
              sx={{
                borderRadius: 2,
                border: 1,
                borderColor: "#e0e0e0",
                backgroundColor: "#ffffff",
              }}
            >
              <Box sx={{ p: 2 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    marginLeft: "0px !important",
                    width: "100% !important",
                  }}
                >
                  <Grid item xs={3}></Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mt: "8px" }}>
                      <InputLabel id="demo-simple-select-label">
                        Robot
                      </InputLabel>
                      <Select
                        value={scoutNumber}
                        label="Robot"
                        onChange={handleScoutNumberChange}
                      >
                        <MenuItem value={"R1"}>R1</MenuItem>
                        <MenuItem value={"R2"}>R2</MenuItem>
                        <MenuItem value={"R3"}>R3</MenuItem>
                        <MenuItem value={"B1"}>B1</MenuItem>
                        <MenuItem value={"B2"}>B2</MenuItem>
                        <MenuItem value={"B3"}>B3</MenuItem>
                        <MenuItem value={"Manual"}>Manual Entry</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mt: "8px" }}>
                      <InputLabel id="demo-simple-select-label">
                        Event
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={event}
                        label="Event"
                        name="event"
                        onChange={handleEventChange}
                      >
                        <MenuItem value={"VABLA"}>VABLA</MenuItem>
                        <MenuItem value={"VAASH"}>VAASH</MenuItem>
                        <MenuItem value={"VAGLE"}>VAGLE</MenuItem>
                        <MenuItem value={"VAFAL"}>VAFAL</MenuItem>
                        <MenuItem value={"CHCMP"}>CHCMP</MenuItem>
                        <MenuItem value={"CMPTX"}>CMPTX</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="scout_name"
                      margin="dense"
                      fullWidth
                      label="Scout Name"
                      type="string"
                      variant="outlined"
                      onChange={handleStringChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="match_number"
                      margin="dense"
                      fullWidth
                      label="Match Number"
                      type="number"
                      variant="outlined"
                      onChange={handleNumberChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="robot_number"
                      margin="dense"
                      fullWidth
                      label="Robot Number"
                      type="number"
                      variant="outlined"
                      onChange={handleNumberChange}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "center" }}>
                    <Button
                      fullWidth
                      disabled={gameTimer > 0}
                      variant="contained"
                      onClick={() => startMatch()}
                      sx={{
                        backgroundColor: "#80e584",
                        ":hover": {
                          backgroundColor: "#61cf65",
                        },
                      }}
                    >
                      Start
                    </Button>
                  </Grid>
                  <Grid item xs={6} sx={{ paddingTop: "24px !important" }}>
                    <Typography align="center">
                      Game Time: {visualTime}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "center" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(formData[`left_auto`])}
                          name="left_auto"
                          onChange={handleCheckBoxChange}
                        />
                      }
                      label="Fully Left Starting Zone in Auto"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Paper elevation={5} sx={{ padding: "10px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <b>Tele Made</b>
                          </Typography>
                        </Grid>
                        {/*Tele Amp*/}
                        <Grid item xs={4}>
                          <Button
                            fullWidth
                            onClick={() => buttonChange("add", "amp_tele")}
                            variant="contained"
                            sx={{
                              backgroundColor: "#80e584",
                              ":hover": {
                                backgroundColor: "#61cf65",
                              },
                            }}
                          >
                            +
                          </Button>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography align="center">
                            Amp: {formData[`amp_tele`]}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Button
                            fullWidth
                            onClick={() => buttonChange("subtract", "amp_tele")}
                            variant="contained"
                            sx={{
                              backgroundColor: "#ff7676",
                              ":hover": {
                                backgroundColor: "#ed6161",
                              },
                            }}
                          >
                            -
                          </Button>
                        </Grid>
                        {/*Tele Speaker*/}
                        <Grid item xs={4}>
                          <Button
                            onClick={() => buttonChange("add", "speaker_tele")}
                            fullWidth
                            variant="contained"
                            sx={{
                              backgroundColor: "#80e584",
                              ":hover": {
                                backgroundColor: "#61cf65",
                              },
                            }}
                          >
                            +
                          </Button>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography align="center">
                            Speaker: {formData[`speaker_tele`]}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Button
                            onClick={() =>
                              buttonChange("subtract", "speaker_tele")
                            }
                            fullWidth
                            variant="contained"
                            sx={{
                              backgroundColor: "#ff7676",
                              ":hover": {
                                backgroundColor: "#ed6161",
                              },
                            }}
                          >
                            {" "}
                            -
                          </Button>
                        </Grid>
                        {/**Tele Trap*/}
                        <Grid item xs={4}>
                          <Button
                            fullWidth
                            onClick={() => buttonChange("add", "trap_tele")}
                            variant="contained"
                            sx={{
                              backgroundColor: "#80e584",
                              ":hover": {
                                backgroundColor: "#61cf65",
                              },
                            }}
                          >
                            +
                          </Button>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography align="center">
                            Trap: {formData[`trap_tele`]}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Button
                            fullWidth
                            onClick={() =>
                              buttonChange("subtract", "trap_tele")
                            }
                            variant="contained"
                            sx={{
                              backgroundColor: "#ff7676",
                              ":hover": {
                                backgroundColor: "#ed6161",
                              },
                            }}
                          >
                            -
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                    <Paper elevation={5} sx={{ padding: "10px", mt: "10px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <b>Tele Missed</b>
                          </Typography>
                        </Grid>
                        {/*Tele Amp*/}
                        <Grid item xs={4}>
                          <Button
                            onClick={() =>
                              buttonChange("add", "missed_amp_tele")
                            }
                            fullWidth
                            variant="contained"
                            sx={{
                              backgroundColor: "#80e584",
                              ":hover": {
                                backgroundColor: "#61cf65",
                              },
                            }}
                          >
                            +
                          </Button>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography align="center">
                            Amp: {formData[`missed_amp_tele`]}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Button
                            onClick={() =>
                              buttonChange("subtract", "missed_amp_tele")
                            }
                            fullWidth
                            variant="contained"
                            sx={{
                              backgroundColor: "#ff7676",
                              ":hover": {
                                backgroundColor: "#ed6161",
                              },
                            }}
                          >
                            -
                          </Button>
                        </Grid>
                        {/*Tele Speaker*/}
                        <Grid item xs={4}>
                          <Button
                            onClick={() =>
                              buttonChange("add", "missed_speaker_tele")
                            }
                            fullWidth
                            variant="contained"
                            sx={{
                              backgroundColor: "#80e584",
                              ":hover": {
                                backgroundColor: "#61cf65",
                              },
                            }}
                          >
                            +
                          </Button>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography align="center">
                            Speaker: {formData[`missed_speaker_tele`]}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Button
                            onClick={() =>
                              buttonChange("subtract", "missed_speaker_tele")
                            }
                            fullWidth
                            variant="contained"
                            sx={{
                              backgroundColor: "#ff7676",
                              ":hover": {
                                backgroundColor: "#ed6161",
                              },
                            }}
                          >
                            -
                          </Button>
                        </Grid>
                        {/*Tele Trap*/}
                        <Grid item xs={4}>
                          <Button
                            onClick={() => buttonChange("add", "missed_trap")}
                            fullWidth
                            variant="contained"
                            sx={{
                              backgroundColor: "#80e584",
                              ":hover": {
                                backgroundColor: "#61cf65",
                              },
                            }}
                          >
                            +
                          </Button>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography align="center">
                            Trap: {formData[`missed_trap_tele`]}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Button
                            onClick={() =>
                              buttonChange("subtract", "missed_trap_tele")
                            }
                            fullWidth
                            variant="contained"
                            sx={{
                              backgroundColor: "#ff7676",
                              ":hover": {
                                backgroundColor: "#ed6161",
                              },
                            }}
                          >
                            -
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sx={{ textAlign: "center", paddingTop: "24px !important" }}
                  >
                    <Typography>
                      <b>Note Retrieval:</b>
                    </Typography>
                  </Grid>

                  <Grid item xs={4} sx={{ textAlign: "center" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(formData[`floor_retrieval`])}
                          name="floor_retrieval"
                          onChange={handleCheckBoxChange}
                        />
                      }
                      label="Floor"
                    />
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: "center" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(formData[`human_dropped_in`])}
                          name="human_dropped_in"
                          onChange={handleCheckBoxChange}
                        />
                      }
                      label="H.P."
                    />
                  </Grid>
                  <Grid item sm={3} xs={12} sx={{ textAlign: "center" }}>
                    <Typography>
                      <b>Endgame: {mobile ? "true" : "false"}</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={9} xs={12}>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        width: "100%",
                      }}
                    >
                      <FormGroup style={{ width: "100%", paddingTop: 8 }}>
                        <RadioGroup
                          aria-label="radio"
                          name="end_position"
                          onChange={handleRadioChange}
                          row={mobile ? "false" : "true"}
                          sx={{
                            alignContent: "center",
                          }}
                        >
                          <Grid item sm={3} xs={12} sx={{ textAlign: "left" }}>
                            <FormControlLabel
                              value={0}
                              control={<Radio />}
                              label="Parked"
                            />
                          </Grid>
                          <Grid item sm={3} xs={12} sx={{ textAlign: "left" }}>
                            <FormControlLabel
                              value={1}
                              control={<Radio />}
                              label="Failed Onstage"
                            />
                          </Grid>

                          <Grid item sm={3} xs={12} sx={{ textAlign: "left" }}>
                            <FormControlLabel
                              value={2}
                              control={<Radio />}
                              label="Onstage"
                            />
                          </Grid>

                          <Grid item sm={3} xs={12} sx={{ textAlign: "left" }}>
                            <FormControlLabel
                              value={3}
                              control={<Radio />}
                              label="Onstage (Spotlit)"
                            />
                          </Grid>
                        </RadioGroup>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid item sm={4} xs={12} sx={{ textAlign: "center" }}>
                    <Typography>
                      <b>Harmony: </b>
                    </Typography>
                  </Grid>
                  <Grid item sm={8} xs={12}>
                    <Grid container spacing={2} sx={{ width: "100%" }}>
                      <FormGroup style={{ width: "100%", paddingTop: 8 }}>
                        <RadioGroup
                          aria-label="radio"
                          name="robot_harmony_count"
                          row
                          onChange={handleRadioChange}
                        >
                          <Grid item sm={4} xs={12} sx={{ textAlign: "left" }}>
                            <FormControlLabel
                              value={0}
                              control={<Radio />}
                              label="1"
                            />
                          </Grid>
                          <Grid item sm={4} xs={12} sx={{ textAlign: "left" }}>
                            <FormControlLabel
                              value={1}
                              control={<Radio />}
                              label="2"
                            />
                          </Grid>

                          <Grid item sm={4} xs={12} sx={{ textAlign: "left" }}>
                            <FormControlLabel
                              value={2}
                              control={<Radio />}
                              label="3"
                            />
                          </Grid>
                        </RadioGroup>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography align="center">
                      <b>Speed:</b>
                    </Typography>
                    <FormGroup style={{ width: "100%" }}>
                      <RadioGroup
                        aria-label="radio"
                        name="speed"
                        onChange={handleRadioChange}
                        sx={{ alignContent: "center" }}
                      >
                        <Grid item lg={3} sm={12} sx={{ textAlign: "left" }}>
                          <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label="Slow"
                          />
                        </Grid>
                        <Grid item lg={3} sm={12} sx={{ textAlign: "left" }}>
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="Average"
                          />
                        </Grid>

                        <Grid item lg={3} sm={12} sx={{ textAlign: "left" }}>
                          <FormControlLabel
                            value={2}
                            control={<Radio />}
                            label="Fast"
                          />
                        </Grid>
                      </RadioGroup>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography align="center">
                      <b>Agility:</b>
                    </Typography>
                    <FormGroup style={{ width: "100%" }}>
                      <RadioGroup
                        aria-label="radio"
                        name="agility"
                        onChange={handleRadioChange}
                        sx={{ alignContent: "center" }}
                      >
                        <Grid item lg={3} sm={12} sx={{ textAlign: "left" }}>
                          <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label="Poor"
                          />
                        </Grid>
                        <Grid item lg={3} sm={12} sx={{ textAlign: "left" }}>
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="Average"
                          />
                        </Grid>

                        <Grid item lg={3} sm={12} sx={{ textAlign: "left" }}>
                          <FormControlLabel
                            value={2}
                            control={<Radio />}
                            label="Good"
                          />
                        </Grid>
                      </RadioGroup>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography align="center">
                      <b>Defense:</b>
                    </Typography>
                    <FormGroup style={{ width: "100%" }}>
                      <RadioGroup
                        aria-label="radio"
                        name="defense"
                        onChange={handleRadioChange}
                        sx={{ alignContent: "center" }}
                      >
                        <Grid item lg={3} sm={12} sx={{ textAlign: "left" }}>
                          <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label="Poor"
                          />
                        </Grid>
                        <Grid item lg={3} sm={12} sx={{ textAlign: "left" }}>
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="Average"
                          />
                        </Grid>

                        <Grid item lg={3} sm={12} sx={{ textAlign: "left" }}>
                          <FormControlLabel
                            value={2}
                            control={<Radio />}
                            label="Strong"
                          />
                        </Grid>
                      </RadioGroup>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography align="center">
                      <b>Climbing Speed:</b>
                    </Typography>
                    <FormGroup style={{ width: "100%" }}>
                      <RadioGroup
                        aria-label="radio"
                        name="climbing_speed"
                        onChange={handleRadioChange}
                        sx={{ alignContent: "center" }}
                      >
                        <Grid item lg={3} sm={12} sx={{ textAlign: "left" }}>
                          <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label="Slow"
                          />
                        </Grid>
                        <Grid item lg={3} sm={12} sx={{ textAlign: "left" }}>
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="Average"
                          />
                        </Grid>

                        <Grid item lg={3} sm={12} sx={{ textAlign: "left" }}>
                          <FormControlLabel
                            value={2}
                            control={<Radio />}
                            label="Fast"
                          />
                        </Grid>
                      </RadioGroup>
                    </FormGroup>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={4}
                    sx={{
                      alignContent: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Stack direction="column">
                      <Typography align="center" component="div">
                        <b>Shooting Distance:</b>
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(formData[`shooting_close`])}
                            name="shooting_close"
                            onChange={handleCheckBoxChange}
                          />
                        }
                        label="Close"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(formData[`shooting_mid`])}
                            name="shooting_mid"
                            onChange={handleCheckBoxChange}
                          />
                        }
                        label="Mid"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(formData[`shooting_far`])}
                            name="shooting_far"
                            onChange={handleCheckBoxChange}
                          />
                        }
                        label="Far"
                      />
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={4}
                    sx={{
                      alignContent: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Stack direction="column">
                      <Typography align="center" component="div">
                        <b>Stage:</b>
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(formData[`under_stage_often`])}
                            name="under_stage_often"
                            onChange={handleCheckBoxChange}
                          />
                        }
                        label="Often Under Stage"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(formData[`under_stage_easily`])}
                            name="under_stage_easily"
                            onChange={handleCheckBoxChange}
                          />
                        }
                        label="Easily Under Stage"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(formData[`under_stage_possible`])}
                            name="under_stage_possible"
                            onChange={handleCheckBoxChange}
                          />
                        }
                        label="Went Under Stage"
                      />
                    </Stack>
                  </Grid>
                  <Grid item sm={3} xs={12} sx={{ alignContent: "center" }}>
                    <Typography align="center">
                      <b>Errors:</b>
                    </Typography>
                  </Grid>
                  <Grid item sm={3} xs={12} sx={{ textAlign: "left" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(formData[`foul`])}
                          name="foul"
                          onChange={handleCheckBoxChange}
                        />
                      }
                      label="Foul"
                    />
                  </Grid>
                  <Grid item sm={3} xs={12} sx={{ textAlign: "left" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(formData[`yellow_card`])}
                          name="yellow_card"
                          onChange={handleCheckBoxChange}
                        />
                      }
                      label="Yellow Card"
                    />
                  </Grid>
                  <Grid item sm={3} xs={12} sx={{ textAlign: "left" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(formData[`red_card`])}
                          name="red_card"
                          onChange={handleCheckBoxChange}
                        />
                      }
                      label="Red Card"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                    }}
                  >
                    <Typography align="center">
                      <b>Concerns:</b>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                    }}
                  >
                    <Typography align="center">Fixes:</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(formData[`concern_comms`])}
                          name="concern_comms"
                          onChange={handleCheckBoxChange}
                        />
                      }
                      label="Lost Communication"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(formData[`concern_damaged`])}
                          name="concern_damaged"
                          onChange={handleCheckBoxChange}
                        />
                      }
                      label="Visible Damage to Robot"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                    }}
                  >
                    <Typography align="center">Design:</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(formData[`concern_note_stuck`])}
                          name="concern_note_stuck"
                          onChange={handleCheckBoxChange}
                        />
                      }
                      label="Note Got Stuck In/On Robot"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(formData[`concern_double_note`])}
                          name="concern_double_note"
                          onChange={handleCheckBoxChange}
                        />
                      }
                      label="Intook 2 Notes at Once"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(formData[`concern_almost_tipped`])}
                          name="concern_almost_tipped"
                          onChange={handleCheckBoxChange}
                        />
                      }
                      label="Robot ALMOST TIPPED"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(formData[`concern_tipped`])}
                          name="concern_tipped"
                          onChange={handleCheckBoxChange}
                        />
                      }
                      label="Robot Tipped"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                    }}
                  >
                    <Typography align="center">Driving:</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(formData[`concern_stuck`])}
                          name="concern_stuck"
                          onChange={handleCheckBoxChange}
                        />
                      }
                      label="Robot Got Stuck"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(formData[`concern_stuck_stage`])}
                          name="concern_stuck_stage"
                          onChange={handleCheckBoxChange}
                        />
                      }
                      label="Robot Got Stuck Under Stage"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(
                            formData[`concern_teammate_off_chain`]
                          )}
                          name="concern_teammate_off_chain"
                          onChange={handleCheckBoxChange}
                        />
                      }
                      label="Knocked Teammate Off Chain"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(formData[`concern_got_in_way`])}
                          name="concern_got_in_way"
                          onChange={handleCheckBoxChange}
                        />
                      }
                      label="Robot Got In Way of Teammates"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(formData[`concern_damaged_others`])}
                          name="concern_damaged_others"
                          onChange={handleCheckBoxChange}
                        />
                      }
                      label="Damaged Other Alliance's Robots"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(formData[`concern_damaged_own`])}
                          name="concern_damaged_own"
                          onChange={handleCheckBoxChange}
                        />
                      }
                      label="Damaged Own Alliance's Robots"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="scout_notes"
                      margin="dense"
                      fullWidth
                      label="Notes"
                      type="string"
                      variant="outlined"
                      onChange={handleStringChange}
                      multiline
                    />
                  </Grid>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={6} sx={{ textAlign: "center" }}>
                    <Button
                      fullWidth
                      //disabled={gameTimer < 153}
                      enabled={gameTimer === 153}
                      onClick={handleCreateMatch}
                      variant="contained"
                      sx={{
                        backgroundColor: "#80e584",
                        ":hover": {
                          backgroundColor: "#61cf65",
                        },
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
